// MUI STYLE
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  appBar: {
    padding: '24px 32px',
    backgroundColor: theme.palette.background.paper,
    flexDirection: 'row',
    justifyContent: 'center',
    height: 80,
    backgroundImage: 'unset',
  },
  toolbar: {
    width: '100%',
    maxWidth: 1200,
    minHeight: 'unset',
    padding: 0,
  },
  product: {
    display: 'flex',
    alignItems: 'center',
  },
}))

export default useStyles