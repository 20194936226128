// MUI STYLE
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  dialogSocialShare: {
    '& .MuiPaper-root': {
      padding: 24,
      borderRadius: 16,
    },
  },
  optionIconButton: {
    height: 76,
    width: 76,
    marginBottom: 12,
    '& .MuiSvgIcon-root': {
      width: 36,
      height: 36,
    },
  },
  urlInput: {
    backgroundColor: theme.palette.common.black,
    borderRadius: 16,
    marginTop: 28,
    border: `1px solid ${theme.palette.grey[700]}`,
    '& .MuiInputBase-root': {
      borderRadius: 16,
    },
  },
  urlInputCopyButton: {
    borderRadius: 16,
  },
}))

export default useStyles