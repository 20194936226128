import { useEffect, useContext } from 'react'

// ASSETS
import LogoProduct from 'assets/images/logos/product-logo.svg'

// BORING AVATAR
import BoringAvatar from 'boring-avatars'

// CONSTANTS
import { basePaths } from 'constants/paths'
import { values } from 'constants/values'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// HOOKS
import useAxiosPrivate from 'hooks/useAxiosPrivate'

// MUI
import MuiAppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'

// SERVICES
import { getUserDetail } from 'services/users'

// STYLES
import useStyles from './appBarUseStyles'

// UTILITIES
import { isRequestValid } from 'utilities/validation'

const AppBar = () => {
  const classes = useStyles()

  const { 
    token,
    setIsLoading,
    setSnackbarObject, 
    userProfile, setUserProfile,
  } = useContext(AllPagesContext)

  const axiosPrivate = useAxiosPrivate()
  
  const getUserDetailData = async (isMounted, abortController) => {
    setIsLoading(true)

    const resultUserDetail = await getUserDetail(axiosPrivate, abortController.signal)

    if (isMounted && resultUserDetail?.status === 200) setUserProfile({
      phoneNumber: resultUserDetail.data.value.phoneNumber,
      name: resultUserDetail.data.value.profileName,
      email: resultUserDetail.data.value.email,
    })
    else if (isRequestValid(resultUserDetail.status)) setSnackbarObject({
      open: true,
      severity: 'error',
      title: '',
      message: `Gagal mengambil profil pengguna dengan error ${resultUserDetail?.data?.message}`,
    })

    setIsLoading(false)
  }

  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()

    if (token) getUserDetailData(isMounted, abortController)

    return () => {
      isMounted = false
      abortController.abort()
    }
  }, [token])

  return (
    <MuiAppBar 
      position='fixed'
      className={classes.appBar}
    >
      <Toolbar className={classes.toolbar}>
        <Link 
          href='/'
          className={classes.product}
          underline='none'
        >
          {/* PRODUCT LOGO */}
          <Box
            component='img'
            src={LogoProduct}
            alt=''
            height={24}
            marginRight={8}
          />

          {/* PRODUCT NAME */}
          <Typography
            variant='h5'
            fontWeight={700}
            color='text.primary'
          >
            {values.appName}
          </Typography>
        </Link>

        {/* PROFILE ICON */}
        {userProfile &&
        <Link
          href={basePaths.profile}
          className='marginLeftAuto' 
        >
          <IconButton>
            <BoringAvatar
              size={28}
              name={userProfile?.name ?? '[Tanpa Nama]'}
              variant='beam'
              colors={[ '#002C2B', '#FF3D00', '#FFBC11', '#0A837F', '#076461' ]}
            />
          </IconButton>
        </Link>}
      </Toolbar>
    </MuiAppBar>
  )
}

export default AppBar