// MUI STYLE
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  tagChip: {
    height: 'fit-content',
    minHeight: 32,
    padding: '4px 2px',
    '& .css-6od3lo-MuiChip-label': {
      overflowWrap: 'break-word', 
      whiteSpace: 'normal', 
      textOverflow: 'clip',
    },
  },
}))

export default useStyles