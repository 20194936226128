// QUERY STRING
import queryString from 'query-string'

export const getAddressFromLatitudeLongitude = async (axiosPrivate, queryParameters, signal) => {
  try {
    const response = await axiosPrivate.get(
      `/geocoder/reverse?${queryString.stringify(queryParameters)}`, 
      { signal }
    )

    return response
  } catch (error) {
    if (error.message === 'canceled') return { status: 'Canceled' }
    else if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}