// CONSTANTS
import { socialMedias } from 'constants/colors'

// MUI ICONS
import IconCode from '@mui/icons-material/Code'
import IconEmail from '@mui/icons-material/Email'
import IconFacebook from '@mui/icons-material/Facebook'
import IconWhatsApp from '@mui/icons-material/WhatsApp'
import IconTwitter from '@mui/icons-material/Twitter'

// REACT SHARE
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share'

export const optionList = [
  {
    label: 'Embed',
    icon: IconCode,
  },
  {
    label: 'WhatsApp',
    icon: IconWhatsApp,
    container: WhatsappShareButton,
    color: 'white',
    backgroundColor: socialMedias.whatsApp,
  },
  {
    label: 'Facebook',
    icon: IconFacebook,
    container: FacebookShareButton,
    color: 'white',
    backgroundColor: socialMedias.facebook,
  },
  {
    label: 'Twitter',
    icon: IconTwitter,
    container: TwitterShareButton,
    color: 'white',
    backgroundColor: socialMedias.twitter,
  },
  {
    label: 'Email',
    icon: IconEmail,
    container: EmailShareButton,
  },
]