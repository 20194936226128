//APIS
import axios from 'apis/axios'

export const postSignInUser = async (
  signal, 
  bodyParams,
) => {
  try {
    const response = await axios.post(
      '/auth/signInWithPhoneNumber', 
      bodyParams, 
      { signal },
    )

    return response
  } catch (error) {
    if (error.message === 'canceled') return { status: 'Canceled' }
    else if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const postVerifyUser = async (
  signal, 
  bodyParams,
) => {
  try {
    const response = await axios.post(
      '/auth/verifyWithPhoneNumber', 
      bodyParams, 
      { signal },
    )

    return response
  } catch (error) {
    if (error.message === 'canceled') return { status: 'Canceled' }
    else if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}