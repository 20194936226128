import { useState, useEffect, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

// CONSTANTS
import { basePaths } from 'constants/paths'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUI
import FormControl from '@mui/material/FormControl'
import Input from '@mui/material/Input'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// SERVICES
import { 
  postSignInUser,
  postVerifyUser, 
} from 'services/auth'

// STYLES
import useStyles from './inputVerificationUseStyles'

// UTILITIES
import { setAccessTokenToLocalStorage } from 'utilities/localStorage'
import { censorPhoneNumber } from 'utilities/string'

const InputVerification = () => {
  const classes = useStyles()

  const { 
    setIsLoading, 
    setSnackbarObject,
    setToken,
  } = useContext(AllPagesContext)

  const location = useLocation()
  const navigate = useNavigate()

  const initialTimer = 30

  const [ enteredOTP, setEnteredOTP ] = useState(new Array(4).fill(''))
  const [ timer, setTimer ] = useState(initialTimer)

  const handleEnteredOTPChange = (element, index) => {
    if (isNaN(element.value)) return false
    else setEnteredOTP(current => {
      const newEnteredOTP = [...current.map((currentItem, currentIndex) => index === currentIndex ? element.value : currentItem)]
      return newEnteredOTP
    })

    if (element.parentNode.parentNode.nextSibling) {
      element.parentNode.parentNode.nextSibling.querySelector('input').focus()
    }
  }

  const isEnteredOtpFull = (inputOTP) => {
    let count = 0
    inputOTP.forEach(item => {
      if (item !== '') count++
    })
    
    return count === 4 ? true : false
  }

  const handleUserVerification = async (isMounted, abortController) => {
    setIsLoading(true)

    const resultUserVerification = await postVerifyUser(
      abortController.signal,
      {
        phoneNumber: location?.state?.phoneNumber,
        otp: enteredOTP.join(''),
        method: location?.state?.method,
      }
    )

    if (isMounted && resultUserVerification?.status === 200) {
      let severity, message, selectedPath

      // JUST REGISTERED = TRUE -> USER IS REDIRECTED TO THE EDIT PROFILE PAGE
      if (resultUserVerification?.data?.value?.justRegistered) {
        severity = 'info'
        message = 'Mohon isi data diri'
        selectedPath = basePaths.profile
      }
      // JUST REGISTERED = FALSE -> USER IS REDIRECTED TO THE CREATE EVIDENCE PAGE
      else {
        severity = 'success'
        message = 'Selamat datang kembali'
        selectedPath = basePaths.createEvidence
      }
      
      setSnackbarObject({
        open: true,
        severity,
        title: '',
        message,
      })

      setAccessTokenToLocalStorage(resultUserVerification.data.value.token.accessToken)
      setToken(resultUserVerification.data.value.token.accessToken)
      
      navigate(selectedPath)
    }
    else setSnackbarObject({
      open: true,
      severity: 'error',
      title: '',
      message: `Gagal verifikasi dengan error "${resultUserVerification?.data?.message}"`,
    })

    abortController.abort()
    setIsLoading(false)
  }

  const handleResendCodeLinkClick = async (event) => {
    event.preventDefault()

    setIsLoading(true)
    const abortController = new AbortController()
  
    const resultSignInUser = await postSignInUser(
      abortController.signal,
      { phoneNumber: location?.state?.phoneNumber, method: location?.state?.method },
    )

    if (resultSignInUser.status === 200) {
      setTimer(initialTimer)

      setSnackbarObject({
        open: true,
        severity: 'success',
        title: '',
        message: `Sukses mengirimkan kode ke ${censorPhoneNumber(location?.state?.phoneNumber)}`,
      })
    }
    else setSnackbarObject({
      open: true,
      severity: 'error',
      title: '',
      message: `Gagal meminta verifikasi dengan error ${resultSignInUser.data.message}`,
    })

    abortController.abort()
    setIsLoading(false)
  }

  useEffect(() => {
    const countdown = setTimeout(() => setTimer(current => current - 1), 1000)

    return () => clearTimeout(countdown)
  }, [timer])

  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()
    
    if (isEnteredOtpFull(enteredOTP)) handleUserVerification(isMounted, abortController)
    
    return () => {
      isMounted = false
      abortController.abort()
    }
  }, [enteredOTP])

  return (
    <Stack 
      width='100%'
      alignItems='center'
      alignSelf='center'
      maxWidth={480}
    >
      {/* INSTRUCTION TEXT */}
      <Typography
        variant='h6'
        color='text.primary'
        fontWeight={600}
        textAlign='center'
      >
        Masukkan Kode Verifikasi
      </Typography>

      {/* DESCRIPTION TEXT */}
      <Typography
        variant='body2'
        color='text.primary'
        marginTop={24}
        marginBottom={40}
        lineHeight={1.625}
        textAlign='center'
      >
        Kode verifikasi telah dikirimkan melalui {location?.state?.method} ke {censorPhoneNumber(location?.state?.phoneNumber)}
      </Typography>

      {/* OTP INPUT FIELDS */}
      <Stack
        direction='row'
        spacing='28px'
        justifyContent='center'
      >
        {enteredOTP.map((item, index) => (
          <FormControl 
            variant='standard'
            key={index}
          >
            <Input
              autoFocus={index === 0}
              type='tel'
              name='otp'
              inputProps={{ maxLength: 1 }}
              value={item}
              onChange={event => handleEnteredOTPChange(event.target, index)}
              onFocus={event => event.target.select()}
              className={classes.otpInput}
            />
          </FormControl>
        ))}
      </Stack>

      {/* WAITING INFORMATION TEXT */}
      {timer > 0 ? (
        <Typography
          color='text.primary'
          marginTop={52}
          textAlign='center'
        >
          Mohon tunggu&nbsp;
          <Typography
            variant='inherit'
            fontWeight={700}
            display='inline'
          >
            {timer} detik
          </Typography>
          &nbsp;untuk mengirim kode verifikasi ulang
        </Typography>
      ) : (
        <Typography
          color='text.primary'
          marginTop={52}
          textAlign='center'
        >
          Tidak menerima kode?&nbsp;
          <Link
            href='#'
            variant='inherit'
            fontWeight={700}
            underline='hover'
            onClick={handleResendCodeLinkClick}
          >
            Kirim ulang
          </Link>
          &nbsp;atau&nbsp;
          <Link
            href={basePaths.signIn}
            variant='inherit'
            fontWeight={700}
            underline='hover'
          >
            ganti nomor telepon
          </Link>
        </Typography>
      )}
    </Stack>
  )
}

export default InputVerification