// MUI
import { alpha } from '@mui/material/styles'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  mediaUploader: {
    '& .uppy-Root, .uppy-Dashboard, .uppy-Dashboard-inner': {
      width: '100% !important',
      height: 254,
    },
    '& .uppy-Dashboard-inner': {
      backgroundColor: theme.palette.background.paper,
      border: 'dashed 0.3px white',
      borderRadius: 4,
    },
    '& .uppy-Dashboard-AddFiles': {
      border: 'none',
    },
    '& .uppy-Dashboard-browse, .uppy-DashboardContent-back, .uppy-DashboardContent-save, .uppy-DashboardContent-addMore': {
      color: theme.palette.primary.main,
    },
    '& .uppy-DashboardContent-bar, .uppy-StatusBar.is-waiting .uppy-StatusBar-actions, .uppy-StatusBar': {
      backgroundColor: `${theme.palette.background.paper} !important`,
    },
    '& .uppy-Dashboard-files': {
      backgroundColor: theme.palette.background.default,
    },
  },
  autocompleteTextField: {
    '& .MuiFilledInput-root': {
      minHeight: 74,
    },
    '& .MuiInputAdornment-root': {
      marginTop: 'unset !important',      
      marginBottom: 20,
      marginLeft: 4,
    },
  },
  autocompleteChip: {
    margin: 3,
    '& .MuiChip-deleteIcon': {
      color: alpha(theme.palette.common.black, 0.6),
    },
  },
  autocompleteListItem: {
    padding: '0px !important',
  },
  autocompleteListItemIcon: {
    minWidth: 'unset !important',
  },
}))

export default useStyles