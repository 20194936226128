import { useState } from 'react'

// COMPONENTS
import InputWithIcon from 'components/InputWithIcon/InputWithIcon'

// CONSTANTS
import { values } from 'constants/values'

// MUIS
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconAccountCircle from '@mui/icons-material/AccountCircle'
import IconEmail from '@mui/icons-material/Email'

const SignUp = () => {
  const initialFormObject = {
    name: '',
    email: '',
  }
  
  const [ formObject, setFormObject ] = useState(initialFormObject)

  const handleFormObjectChange = (event) => {
    setFormObject(current => {
      return {
        ...current,
        [event.target.name]: event.target.value,
      }
    })
  }

  const handleSubmitForm = (event) => {
    event.preventDefault()
    // TO DO: VALIDATE THE FORM HERE
  }
  
  return (
    <Stack 
      width='100%'
      alignItems='center'
      alignSelf='center'
      component='form'
      onSubmit={handleSubmitForm}
      spacing={24}
    >
      {/* DESCRIPTION TEXT */}
      <Typography
        variant='body2'
        color='text.primary'
        lineHeight={1.625}
        textAlign='center'
      >
        Masukkan nama dan email untuk mulai menggunakan layanan di {values.appName}
      </Typography>

      {/* NAME INPUT */}
      <InputWithIcon
        Icon={IconAccountCircle}
        isRequired
        label='Nama'
        autoFocus
        type='text'
        name='name'
        value={formObject.name}
        onChange={handleFormObjectChange}
      />

      {/* EMAIL INPUT */}
      <InputWithIcon
        Icon={IconEmail}
        isRequired
        label='Email'
        type='email'
        name='email'
        value={formObject.email}
        onChange={handleFormObjectChange}
      />

      {/* SEND BUTTON */}
      <Button
        variant='contained'
        fullWidth
        type='submit'
        size='large'
        disabled={formObject.name === '' || formObject.email === ''}
      >
        Lanjut
      </Button>
    </Stack>
  )
}

export default SignUp