import { useState, useEffect, useRef } from 'react'

// ASSETS
import PictureBrokenVideo from 'assets/images/pictures/broken-video.svg'

// MUIS
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'

// MUI ICONS
import IconVolumeOff from '@mui/icons-material/VolumeOff'
import IconVolumeUp from '@mui/icons-material/VolumeUp'

// STYLES
import useStyles from './carousellMediasUseStyles'

// VIDEO THUMBNAIL
// import VideoThumbnail from 'react-video-thumbnail'

const MediaVideo = (props) => {
  const { 
    carouselItem, 
    activeIndex,
    carouselIndex,
  } = props

  const classes = useStyles()

  const videoRef = useRef()

  const mediaUrl = `${process.env.REACT_APP_TUS_ENDPOINT}/${carouselItem?.path}`

  const [ source, setSource ] = useState(mediaUrl)
  const [ isLoading, setIsLoading ] = useState(true)
  // const [ thumbnailImage, setThumbnailImage ] = useState(null)
  const [ isMuted, setIsMuted ] = useState(true)

  // PLAY OR PAUSE THE VIDEO BASED ON THE ACTIVE INDEX ON THE CAROUSELL COMPONENT
  useEffect(() => {
    if (videoRef.current && typeof(carouselIndex) !== 'undefined') {
      if (activeIndex === carouselIndex) videoRef.current.play()
      else videoRef.current.pause()
    }
  }, [videoRef.current, activeIndex])

  // PLAY OR PAUSE THE VIDEO BASED ON WHETHER THE VIDEO IS SHOWN OR NOT ON THE SCREEN
  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) videoRef.current.play()
        else videoRef.current.pause()
      },
      { threshold: 1 }
    )

    if (videoRef.current) observer.observe(videoRef.current)
  
    return () => {
      if (videoRef.current) observer.unobserve(videoRef.current)
    }
  }, [videoRef.current])

  return (
    <Stack
      position='relative'
      height='100%'
      width='100%'
      justifyContent='center'
      alignItems='center'
      backgroundColor='#000000'
    >
      {/* ACTION BUTTON */}
      {source &&
      <IconButton 
        onClick={() => setIsMuted(current => !current)}
        className={classes.mediaVideoMuteIconButton}
        size='small'
      >
        {isMuted ? (
          <IconVolumeOff 
            color='action.active'
            fontSize='small'
          />
        ) : (
          <IconVolumeUp 
            color='action.active'
            fontSize='small'
          />
        )}
      </IconButton>}

      {/* BLURRED BACKGROUND */}
      {/* <Box
        key={thumbnailImage}
        sx={{ backgroundImage: `url(${thumbnailImage})` }}
        className={classes.mediaBlurredBackground}
      /> */}

      {/* VIDEO THUMBNAIL GENERATOR */}
      {/* {videoRef?.current &&
      <VideoThumbnail
        videoUrl={`${process.env.REACT_APP_TUS_ENDPOINT}/${carouselItem?.path}`}
        thumbnailHandler={(thumbnail) => setThumbnailImage(thumbnail)}
        width={videoRef.current.videoWidth}
        height={videoRef.current.videoHeight}
        className='displayNone'
      />} */}

      {/* VIDEO */}
      <Box
        ref={videoRef}
        component={source ? 'video' : 'img'}
        src={source ? source : PictureBrokenVideo}
        autoPlay={true}
        muted={isMuted}
        controls={false}
        playsInline
        loop
        alt=''
        className={classes.mediaItemVisual}
        onLoadEnd={() => setIsLoading(false)}
        onError={() => setSource(PictureBrokenVideo)}
      />

      {/* LOADING */}
      {isLoading &&
      <CircularProgress/>}
    </Stack>
  )
}

export default MediaVideo