import { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'

// COMPONENTS
import CarousellMedias from 'components/CarousellMedias/CarousellMedias'
import DialogSocialShare from 'components/DialogSocialShare/DialogSocialShare'
import InformationItem from './InformationItem'

// CONSTANTS
import { values } from 'constants/values'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// HOOKS
import useAxiosPrivate from 'hooks/useAxiosPrivate'

// MUIS
import Chip from '@mui/material/Chip'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconDescription from '@mui/icons-material/Description'
import IconLink from '@mui/icons-material/Link'
import IconLocationOn from '@mui/icons-material/LocationOn'
import IconTag from '@mui/icons-material/Tag'

// SERVICES
import { getEvidenceDetailByUrlCode } from 'services/evidence'

// STYLES
import useAppStyles from 'appUseStyles'
import useStyles from './evidenceDetailUseStyles'

// UTILITIES
import { isRequestValid } from 'utilities/validation'

const EvidenceDetail = () => {
  const { evidenceUrlCode } = useParams()

  const appClasses = useAppStyles()
  const classes = useStyles()

  const axiosPrivate = useAxiosPrivate()

  const { 
    breakpointType,
    setSnackbarObject, 
  } = useContext(AllPagesContext)

  const [ evidenceDetailObject, setEvidenceDetailObject ] = useState(null)
  const [ dialogSocialShare, setDialogSocialShare ] = useState(null)

  const selectedTagList = values.tagList.filter(item => evidenceDetailObject?.tagNos?.includes(item.id))

  const getEvidenceDetail = async (isMounted, abortController) => {
    const responseEvidenceDetail = await getEvidenceDetailByUrlCode(axiosPrivate, evidenceUrlCode, abortController.signal)
    
    if (responseEvidenceDetail?.status === 200) setEvidenceDetailObject(responseEvidenceDetail?.data)
    else if (isRequestValid(responseEvidenceDetail.status)) setSnackbarObject({
      open: true,
      severity: 'error',
      title: '',
      message: `Gagal mendapatkan detail kejadian dengan error ${responseEvidenceDetail?.data?.message}`,
    })
  }

  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()

    getEvidenceDetail(isMounted, abortController)

    return () => {
      isMounted = false
      abortController.abort()
    }
  }, [])

  return (
    <>
      {/* HEADER */}
      <Stack
        direction='row'
        justifyContent='space-between'
        spacing={8}
      >
        {/* TTTLE */}
        <Typography
          variant='h5'
          fontWeight={600}
          marginBottom={24}
          color='primary'
        >
          Laporan {evidenceDetailObject?.urlCode}
        </Typography>

        {/* SOCIAL SHARE BUTTON */}
        {breakpointType !== 'xs' &&
        <IconButton
          size='small'
          className={appClasses.socialShareIconButton}
          onClick={() => setDialogSocialShare(evidenceDetailObject)}
        >
          <IconLink fontSize='small'/>
        </IconButton>}
      </Stack>

      {/* MEDIA CAROUSELL */}
      {evidenceDetailObject?.mediaResponses?.length > 0 &&
      <CarousellMedias 
        medias={evidenceDetailObject?.mediaResponses}
        marginBottom={{ xs: 24, sm: 32 }}
      />}

      <Stack spacing={24}>
        {/* DESCRIPTION INFORMATION */}
        <InformationItem
          Icon={IconDescription}
          label='Uraian Singkat'
          content={
            <Typography
              variant='body2'
              color='text.primary'
            >
              {evidenceDetailObject?.description}
            </Typography>
          }
        />

        {/* TAGS INFORMATION */}
        <InformationItem
          Icon={IconTag}
          label='Tag'
          content={
            <Stack 
              gap={8} 
              marginTop={4}
              direction='row'
              flexWrap='wrap'
              width='100%'
              maxWidth='100%'
            >
              {selectedTagList.length > 0 ? selectedTagList.map((item, index) => (
                <Chip 
                  key={index}
                  variant='filled'
                  icon={<item.icon fontSize='small'/>} 
                  label={item.label}
                  color='primary'
                  className={classes.tagChip}
                />
              )) : (
                <Typography
                  variant='body2'
                  color='text.primary'
                >
                  -
                </Typography>
              )}
            </Stack>
          }
        />

        {/* ADDRESS INFORMATION */}
        <InformationItem
          Icon={IconLocationOn}
          label='Lokasi'
          content={
            <Typography
              variant='body2'
              color='text.primary'
            >
              {evidenceDetailObject?.address}
            </Typography>
          }
        />
      </Stack>

      {/* DIALOG SOCIAL SHARE */}
      <DialogSocialShare
        dialogSocialShare={dialogSocialShare} 
        setDialogSocialShare={setDialogSocialShare}
      />
    </>
  )
}

export default EvidenceDetail