import PropTypes from 'prop-types'

// MUI
import Input from '@mui/material/Input'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Stack from '@mui/material/Stack'

const InputWithIcon = (props) => {
  const {
    Icon,
    isRequired, 
    label,
    ...others
  } = props

  return (
    <Stack
      direction='row'
      alignItems='center'
      spacing={12}
      width='100%'
    >
      {/* ICON */}
      <Icon 
        color='action' 
        className='inputIcon'
      />

      {/* INPUT */}
      <FormControl 
        required={isRequired}
        variant='standard' 
        fullWidth
      >
        {/* LABEL */}
        <InputLabel>
          {label}
        </InputLabel>

        {/* INPUT */}
        <Input {...others}/>
      </FormControl>
    </Stack>
  )
}

InputWithIcon.defaultProps = {
  Icon: {},
  isRequired: false,
  label: '',
}

InputWithIcon.propTypes = {
  Icon: PropTypes.object.isRequired,
  isRequired: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
}

export default InputWithIcon