import PropTypes from 'prop-types'

// COMPONENTS
import AppBar from 'components/AppBar/AppBar'

import Stack from '@mui/material/Stack'

const Private = (props) => {
  const { children } = props
  
  return (
    <Stack 
      flex={1}
      height='100vh'
      alignItems='center'
      padding={{ 
        xs: '80px 24px 24px', 
        sm: '80px 32px 32px', 
      }}
      overflow='auto'
      className='zoom'
    >
      {/* APP BAR */}
      <AppBar/>

      {/* CONTENT */}
      <Stack
        width='100%'
        maxWidth={600}
        paddingTop={{ xs: 24, sm: 32 }}
      >
        {children}
      </Stack>
    </Stack>
  )
}

Private.defaultProps = {
  children: <></>,
}

Private.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Private