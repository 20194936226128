import { useState, useEffect, useContext } from 'react'

// COMPONENTS
import InputWithIcon from 'components/InputWithIcon/InputWithIcon'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// HOOKS
import useAxiosPrivate from 'hooks/useAxiosPrivate'

// MUI
import Button from '@mui/material/Button'
import InputAdornment from '@mui/material/InputAdornment'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconAccountCircle from '@mui/icons-material/AccountCircle'
import IconEmail from '@mui/icons-material/Email'
import IconPhone from '@mui/icons-material/Phone'

// SERVICES
import { putUpdateUserDetail } from 'services/users'

// UTILITIES
import { removeAccessTokenFromLocalStorage } from 'utilities/localStorage'
import { isRequestValid } from 'utilities/validation'

const Profile = () => {
  const { 
    setToken, 
    setIsLoading, 
    setSnackbarObject,
    userProfile,
  } = useContext(AllPagesContext)

  const axiosPrivate = useAxiosPrivate()
  
  const initialFormObject = {
    phoneNumber: '',
    name: '',
    email: '',
  }

  const [ formObject, setFormObject ] = useState(initialFormObject)

  const handleSubmitForm = async (event) => {
    event.preventDefault()
    setIsLoading(true)
    const abortController = new AbortController()
    
    const resultUpdateUser = await putUpdateUserDetail(
      axiosPrivate, 
      {
        profileName: formObject.name,
        email: formObject.email,
      }, 
      abortController.signal
    )

    if (resultUpdateUser.status === 200) setSnackbarObject({
      open: true,
      severity: 'success',
      title: '',
      message: 'Sukses mengubah profil',
    })
    else if (isRequestValid(resultUpdateUser.status)) setSnackbarObject({
      open: true,
      severity: 'error',
      title: '',
      message: `Gagal mengubah profil dengan error ${resultUpdateUser?.data?.message}`,
    })
    
    setIsLoading(false)
    abortController.abort()
  }

  const handleFormObjectChange = (event) => {
    setFormObject(current => {
      return {
        ...current,
        [event.target.name]: event.target.value,
      }
    })
  }

  const handleLogOutButtonClick = () => {
    setToken(null)
    removeAccessTokenFromLocalStorage()
  }

  useEffect(() => {
    if (userProfile) setFormObject(userProfile)
  }, [userProfile])

  return (
    <Stack 
      spacing={24}
      alignItems='center'
      component='form'
      onSubmit={handleSubmitForm}
    >
      {/* PHONE NUMBER INPUT */}
      <InputWithIcon
        disabled
        Icon={IconPhone} 
        label='Nomor Telpon'
        autoFocus
        type='tel'
        name='phoneNumber'
        value={formObject.phoneNumber}
        onChange={handleFormObjectChange}
        startAdornment={
          <InputAdornment position='start'>
            <Typography variant='body2'>
              +62
            </Typography>
          </InputAdornment>
        }
      />

      {/* NAME INPUT */}
      <InputWithIcon
        Icon={IconAccountCircle}
        isRequired
        label='Nama'
        type='text'
        name='name'
        value={formObject.name}
        onChange={handleFormObjectChange}
      />

      {/* EMAIL INPUT */}
      <InputWithIcon
        Icon={IconEmail}
        isRequired
        label='Email'
        type='email'
        name='email'
        value={formObject.email}
        onChange={handleFormObjectChange}
      />

      {/* SEND BUTTON */}
      <Button
        variant='contained'
        fullWidth
        type='submit'
        size='large'
        disabled={formObject.phoneNumber === '' || formObject.name === '' || formObject.email === ''}
      >
        Simpan
      </Button>

      {/* LOG OUT BUTTON */}
      <Button
        variant='text'
        fullWidth
        size='large'
        onClick={handleLogOutButtonClick}
      >
        Log Out
      </Button>
    </Stack>
  )
}

export default Profile