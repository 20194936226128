// MUI STYLE
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  verificationOptionItem: {
    width: '100%',
    height: 56,
    backgroundColor: theme.palette.background.paper,
    marginBottom: 16,
    borderRadius: 4,
    border: `1px solid ${theme.palette.primary.main}`,
    '& .MuiListItemIcon-root': {
      minWidth: 'unset',
      marginRight: 12,
    },
    '& .MuiListItemText-primary': {
      color: theme.palette.text.secondary,
    },
    '& .MuiListItemText-secondary': {
      color: theme.palette.text.primary,
    },
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary, & .MuiListItemText-secondary': {
        color: theme.palette.background.default,
      },
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },
}))

export default useStyles