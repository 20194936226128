import PropTypes from 'prop-types'

// COMPONENTS
import ProductInfo from 'components/ProductInfo/ProductInfo'

// MUI
import Stack from '@mui/material/Stack'

const Authentication = (props) => {
  const { children } = props
  
  return (
    <Stack 
      justifyContent='center'
      alignItems='center'
      flex={1}
      width='100%' 
      className='zoom' 
      padding={{ xs: 24, sm: 32 }}
    >
      <Stack 
        spacing={40} 
        width='100%'
        maxWidth={600}
      >
        {/* PRODUCT INFO */}
        <ProductInfo/>

        {/* CHILDREN */}
        {children}
      </Stack>
    </Stack>
  )
}

Authentication.defaultProps = {
  children: <></>,
}

Authentication.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Authentication