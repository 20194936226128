// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  alertContainer: {
    borderRadius: 4,
    '& .MuiSvgIcon-root': {
      color: theme.palette.common.white,
    },
  },
  title: {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
  },
  message: {
    display: '-webkit-box',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 5,
    color: theme.palette.common.white,
  },
}))

export default useStyles