import { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'

// CONSTANTS
import { basePaths } from 'constants/paths'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// LAYOUTS
import Authentication from 'layouts/Authentication/Authentication'

function AuthenticationRoute(props) {
  const { children } = props

  const { token } = useContext(AllPagesContext)

  return (
    token ? 
      <Navigate 
        replace 
        to={basePaths.home}
      /> :
      <Authentication>
        {children}
      </Authentication>
  )
}

AuthenticationRoute.defaultProps = {}

AuthenticationRoute.propTypes = {
  children: PropTypes.node.isRequired,
}

export default AuthenticationRoute