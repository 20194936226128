import { useState } from 'react'

// ASSETS
import PictureBrokenImage from 'assets/images/pictures/broken-image.svg'

// MUIS
import Box from '@mui/material/Box'

// STYLES
import useStyles from './carousellMediasUseStyles'

const MediaImage = (props) => {
  const { carouselItem } = props

  const classes = useStyles()

  const mediaUrl = `${process.env.REACT_APP_TUS_ENDPOINT}/${carouselItem?.path}`

  const [ source, setSource ] = useState(mediaUrl)
  const [ isUrlValid, setIsUrlValid ] = useState(true)

  const handleInvalidUrl = () => {
    setSource(PictureBrokenImage)
    setIsUrlValid(false)
  }

  return (
    <>
      {/* BLURRED BACKGROUND */}
      <Box
        sx={{ backgroundImage: `url(${mediaUrl})` }}
        className={classes.mediaBlurredBackground}
      />
      
      {/* IMAGE */}
      <Box
        component='img'
        src={source ? source : PictureBrokenImage}
        alt=''
        onError={handleInvalidUrl}
        className={isUrlValid ? classes.mediaItemVisual : classes.mediaInvalidSource}
      />
    </>
  )
}

export default MediaImage