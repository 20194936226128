import { useEffect, useContext } from 'react'

// APIS
import { axiosPrivate } from 'apis/axios'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// UTILITIES
import { setAccessTokenToLocalStorage } from 'utilities/localStorage'

const useAxiosPrivate = () => {
  const { 
    token, setToken, 
    setSnackbarObject,
  } = useContext(AllPagesContext)

  useEffect(() => {
    const requestIntercept = axiosPrivate?.interceptors?.request?.use(
      config => {
        if (!config?.headers['Authorization']) config.headers['Authorization'] = `Bearer ${token}`

        return config
      },
      (error) => Promise.reject(error)
    )

    const responseIntercept = axiosPrivate?.interceptors?.response?.use(
      response => response,
      async (error) => {
        const previousRequest = error?.config

        if ((error?.response?.status === 401) && !previousRequest?.sent) {
          previousRequest.sent = true

          // SIGN OUT
          setSnackbarObject({
            open: false,
            severity: 'error',
            title: '',
            message: 'Maaf, sesi Anda sudah habis',
          })
          setToken('')
          setAccessTokenToLocalStorage('')

          return axiosPrivate(previousRequest)
        }
        
        return Promise.reject(error)
      }
    )

    return () => {
      axiosPrivate?.interceptors?.request?.eject(requestIntercept)
      axiosPrivate?.interceptors?.request?.eject(responseIntercept)
    }
  }, [token])

  return axiosPrivate
}

export default useAxiosPrivate