import { useContext } from 'react'

// COMPONENTS
import CarousellMedias from 'components/CarousellMedias/CarousellMedias'

// CONSTANTS
import { basePaths } from 'constants/paths'
import { values } from 'constants/values'
import PropTypes from 'prop-types'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUIS
import Chip from '@mui/material/Chip'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconLink from '@mui/icons-material/Link'

// STYLES
import useAppStyles from 'appUseStyles'

// UTILITIES
import { convertDate } from 'utilities/date'

const CardEvidence = (props) => {
  const { 
    evidenceObject, 
    onShareIconButtonClick,
  } = props

  const appClasses = useAppStyles()

  const { breakpointType } = useContext(AllPagesContext)

  return (
    <Stack>
      {/* HEADER */}
      <Stack
        direction='row'
        justifyContent='space-between'
        spacing={8}
      >
        {/* TITLE */}
        <Link 
          href={`${basePaths.evidenceDetail}/${evidenceObject?.urlCode}`}
          underline='hover'
          fontWeight={600}
          fontSize={14}
          marginBottom={16}
          noWrap
        >
          LAPORAN {evidenceObject?.urlCode}
        </Link>

        {/* SOCIAL SHARE BUTTON */}
        {breakpointType !== 'xs' &&
        <IconButton
          size='small'
          className={appClasses.socialShareIconButton}
          onClick={() => onShareIconButtonClick(evidenceObject)}
        >
          <IconLink fontSize='small'/>
        </IconButton>}
      </Stack>

      {/* ADDRESS TEXT */}
      <Typography
        variant='body2'
        color='text.primary'
        marginBottom={4}
      >
        {evidenceObject?.address ?? '[Tidak ada informasi alamat]'}
      </Typography>

      {/* DATE AND TIME TEXT */}
      <Typography
        variant='body2'
        color='text.secondary'
        marginBottom={8}
      >
        {evidenceObject?.createdOn 
          ? convertDate(evidenceObject?.createdOn, 'dd MMMM yyyy, hh:mm') 
          : '[Tidak ada informasi waktu]'}
      </Typography>

      {/* MEDIA CAROUSELL */}
      {evidenceObject?.mediaResponses?.length > 0 &&
      <CarousellMedias 
        medias={evidenceObject?.mediaResponses}
        marginBottom={12}
      />}

      {/* DESCRIPTION TEXT */}
      <Typography
        variant='body2'
        color='text.primary'
        whiteSpace='normal'
        textOverflow='clip'
        className='overflowWrapBreakWord'
      >
        {evidenceObject?.description ?? ['Tidak ada informasi deskripsi']}
      </Typography>

      {/* TAG LIST */}
      {evidenceObject.tagNos.length > 0 &&
      <Stack
        direction='row'
        gap={8}
        marginTop={16}
        flexWrap='wrap'
      >
        {evidenceObject.tagNos.map(tagNo => {
          return values.tagList.find(tagObject => tagObject.id === tagNo)
        }).map((tagObject, index) => (
          <Chip 
            key={index}
            variant='filled'
            icon={<tagObject.icon fontSize='small'/>} 
            label={tagObject.label} 
            color='primary'
          />
        ))}
      </Stack>}
    </Stack>
  )
}

CardEvidence.defaultProps = {
  evidenceObject: {},
}

CardEvidence.propTypes = {
  evidenceObject: PropTypes.object.isRequired,
  onShareIconButtonClick: PropTypes.func.isRequired,
}

export default CardEvidence