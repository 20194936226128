import { useEffect, useContext } from 'react'
import { Routes, Route } from 'react-router-dom'

// ASSETS
import LogoProduct from 'assets/images/logos/product-logo.svg'

// COMPONENTS
import AuthenticationRoute from 'components/Routes/AuthenticationRoute'
import PrivateRoute from 'components/Routes/PrivateRoute'
import Snackbar from 'components/Snackbar/Snackbar'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUI
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'

// ROUTES
import routes from 'routes/routes'

// STYLES
import useStyles from './appUseStyles'

const App = () => {
  const classes = useStyles()

  const theme = useTheme()

  const { 
    isLoading, 
    snackbarObject, setSnackbarObject,
  } = useContext(AllPagesContext)
  
  const getRouteComponent = (inputItem) => {
    if (inputItem.routeType === 'authentication') {
      return (
        <AuthenticationRoute>
          {inputItem.element}
        </AuthenticationRoute>
      )
    }
    else if (inputItem.routeType === 'private') {
      return (
        <PrivateRoute>
          {inputItem.element}
        </PrivateRoute>
      )
    }
    else if (inputItem.routeType === 'free') return inputItem.element
  }

  useEffect(() => {
    const faviconElement = document.getElementById('favicon')
    faviconElement.href = LogoProduct
  }, [])

  return (
    <>
      {/* PAGES */}
      <Stack 
        minHeight='100vh'
        backgroundColor={theme.palette.background.default}
        className='no-zoom'
      >
        <Routes>
          {routes.map((item, index) => (
            <Route 
              key={index}
              path={item.path} 
              element={getRouteComponent(item)}
            />
          ))}
        </Routes>
      </Stack>

      {/* LOADING BACKDROP */}
      <Backdrop
        className={classes.backdrop}
        open={isLoading}
      >
        {/* LOADING INDICATOR */}
        <CircularProgress 
          color='primary'
          size={60}
        />
      </Backdrop>

      {/* SNACKBAR */}
      <Snackbar
        open={snackbarObject.open}
        setToast={setSnackbarObject}
        severity={snackbarObject.severity}
        title={snackbarObject.title}
        message={snackbarObject.message}
      />
    </>
  )
}

export default App