import PropTypes from 'prop-types'

// MUIS
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

const InformationItem = (props) => {
  const {
    Icon,
    label,
    content,
  } = props

  return (
    <Stack
      direction='row'
      alignItems='center'
      spacing={16}
    >
      {/* ICON */}
      <Icon color='action'/>

      {/* CONTENT */}
      <Stack>
        {/* LABEL */}
        <Typography
          variant='caption'
          color='text.secondary'
        >
          {label}
        </Typography>

        {/* CONTENT */}
        {content}
      </Stack>
    </Stack>
  )
}

InformationItem.defaultProps = {
  label: '[Tidak ada label]',
  content: '[Tidak ada informasi]',
}

InformationItem.propTypes = {
  Icon: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([ PropTypes.node, PropTypes.string ]).isRequired,
}

export default InformationItem