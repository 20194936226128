export const getUserDetail = async (axiosPrivate, signal) => {
  try {
    const response = await axiosPrivate.get(
      '/users/me', 
      { signal }
    )

    return response
  } catch (error) {
    if (error.message === 'canceled') return { status: 'Canceled' }
    else if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const putUpdateUserDetail = async (axiosPrivate, bodyParameters, signal) => {
  try {
    const response = await axiosPrivate.put(
      '/users/me', 
      bodyParameters,
      { signal }
    )

    return response
  } catch (error) {
    if (error.message === 'canceled') return { status: 'Canceled' }
    else if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}