// CONSTANTS
import { values } from 'constants/values'

// MUI STYLE
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  otpInput: {
    width: 30,
    height: 45,
    fontSize: 30,
    fontFamily: values.fontFamilyOpenSans,
    '& .MuiInputBase-input': {
      textAlign: 'center',
    },
  },
}))

export default useStyles