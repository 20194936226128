import { useState } from 'react'

// CAROUSEL
import Carousel from 'react-material-ui-carousel'

// COMPONENTS
import MediaImage from './MediaImage'
import MediaVideo from './MediaVideo'

// MUIS
import MobileStepper from '@mui/material/MobileStepper'
import Stack from '@mui/material/Stack'
import { alpha } from '@mui/material/styles'

// STYLES
import useStyles from './carousellMediasUseStyles'

const CarousellMedias = (props) => {
  const { 
    medias, 
    ...others
  } = props

  const classes = useStyles()

  const [ activeIndex, setActiveIndex ] = useState(0)

  const getCardMedia = (carouselItem, carouselIndex) => {
    if (carouselItem.mediaType === 'IMAGE') {
      return <MediaImage carouselItem={carouselItem}/>
    }
    else if (carouselItem.mediaType === 'VIDEO') {
      return (
        <MediaVideo 
          carouselItem={carouselItem}
          activeIndex={activeIndex}
          carouselIndex={carouselIndex}
        />
      )
    }
  }

  return (
    <Stack
      width='100%'
      height={375}
      alignItems='center'
      justifyContent='center'
      position='relative'
      borderRadius={4}
      {...others}
    >
      {/* SINGLE ITEM */}
      {medias?.length === 1 &&
      <Stack
        width='100%'
        height='100%'
        justifyContent='center'
        alignItems='center'
        borderRadius='inherit'
        position='relative'
        overflow='hidden'
        backgroundColor={alpha('#FFFFFF', 0.1)}
      >
        {getCardMedia(medias[0])}
      </Stack>}

      {/* CAROUSEL */}
      {medias?.length > 1 &&
      <Carousel
        autoPlay={false}
        indicators={false}
        navButtonsAlwaysVisible={true}
        animation='slide'
        className={classes.carousell}
        index={activeIndex}
        onChange={(now, previous) => setActiveIndex(now)}
      >
        {medias.map((carouselItem, carouselIndex) => (
          <Stack
            key={carouselIndex}
            height={375}
            maxHeight='100%'
            justifyContent='center'
            alignItems='center'
            backgroundColor={alpha('#FFFFFF', 0.1)}
            position='relative'
          >
            {getCardMedia(carouselItem, carouselIndex)}
          </Stack>
        ))}
      </Carousel>}

      {/* STEPPER */}
      {medias?.length > 1 &&
      <MobileStepper
        variant='dots'
        steps={medias.length}
        activeStep={activeIndex}
        className={classes.stepper}
      />}
    </Stack>
  )
}

export default CarousellMedias