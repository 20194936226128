import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

// COMPONENTS
import ProductInfo from 'components/ProductInfo/ProductInfo'

// CONSTANTS
import { basePaths } from 'constants/paths'

// MUI
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

const ThankYou = () => {
  const [ timer, setTimer ] = useState(10)
  
  const navigate = useNavigate()

  useEffect(() => {
    const countdown = setTimeout(() => setTimer(current => current - 1), 1000)

    if (timer === 0) navigate('/')

    return () => clearTimeout(countdown)
  }, [timer])

  return (
    <Stack
      justifyContent='center'
      alignItems='center'
      flex={1}
      width='fit-content'
      alignSelf='center'
      spacing={24}
    >
      {/* PRODUCT INFO */}
      <ProductInfo/>

      {/* INSTRUCTION TEXT */}
      <Typography
        variant='h6'
        color='text.primary'
        fontWeight={600}
        textAlign='center'
      >
        Terima kasih telah menyampaikan laporan
      </Typography>

      {/* DESCRIPTION TEXT */}
      <Typography
        variant='body2'
        color='text.primary'
        lineHeight={1.625}
        textAlign='center'
      >
        Silahkan klik tombol dibawah ini untuk membuat laporan baru
      </Typography>

      {/* CREATE EVIDENCE BUTTON */}
      <Button
        variant='contained'
        size='large'
        href={basePaths.createEvidence}
        fullWidth
      >
        Isi Formulir Kejadian
      </Button>

      {/* REDIRECT TO THE HOME PAGE TEXT */}
      <Typography
        variant='body2'
        color='text.primary'
        lineHeight={2.5}
        marginTop='60px !important'
        textAlign='center'
      >
        kembali ke halaman utama dalam {timer} detik<br/>
        lanjut ke&nbsp;
        <Link
          href='/'
          underline='hover'
        >
          halaman utama
        </Link>
        &nbsp;langsung
      </Typography>
    </Stack>
  )
}

export default ThankYou