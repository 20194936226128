// QUERY STRING
import queryString from 'query-string'

export const getEvidenceDetailByUrlCode = async (axiosPrivate, urlCode, signal) => {
  try {
    const response = await axiosPrivate.get(
      `/evidence/${urlCode}`, 
      { signal }
    )

    return response
  } catch (error) {
    if (error.message === 'canceled') return { status: 'Canceled' }
    else if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const postSubmitEvidence = async (
  axiosPrivate,
  bodyParams, 
  signal,
) => {
  try {
    const response = await axiosPrivate.post(
      '/evidence/submit', 
      bodyParams, 
      { signal },
    )

    return response
  } catch (error) {
    if (error.message === 'canceled') return { status: 'Canceled' }
    else if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const postSearchEvidenceList = async (
  axiosPrivate,
  requestParams, 
  signal,
) => {
  try {
    const response = await axiosPrivate.post(
      `/evidence/search?${queryString.stringify(requestParams)}`, 
      {}, 
      { signal },
    )

    return response
  } catch (error) {
    if (error.message === 'canceled') return { status: 'Canceled' }
    else if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}