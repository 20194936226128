// CONSTANTS
import { values } from 'constants/values'

// MUIS
import MuiGlobalStyles from '@mui/material/GlobalStyles'
import { alpha } from '@mui/material/styles'

const GlobalStyles = () => {
  return (
    <MuiGlobalStyles
      styles={(theme) => ({
        // ALL ELEMENTS
        '*, *::before, *::after': {
          boxSizing: 'border-box',
          fontFamily: values.fontFamilyOpenSans,
          shapeRendering: 'geometricPrecision',
          textRendering: 'geometricPrecision',
          imageRendering: 'optimizeQuality',
          fillRule: 'evenodd',
          clipRule: 'evenodd',
        },

        // GENERAL
        '.centerFixedPosition': {
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        },
        '.displayNone': {
          display: 'none !important',
        },
        '.inputIcon': {
          marginTop: 12,
        },
        '.marginLeftAuto': {
          marginLeft: 'auto',
        },
        '.overflowWrapBreakWord': {
          overflowWrap: 'break-word',
        },
        '.padding0': {
          padding: 0,
        },
        '.width100': {
          width: '100%',
        },

        // SCROLLBAR
        '&::-webkit-scrollbar': {
          width: 5,
          height: 5,
          backgroundColor: alpha('#000000', 0.16),
        },
        '&::-webkit-scrollbar-thumb': {
          width: 5,
          height: 5,
          backgroundColor: alpha('#000000', 0.2),
        },

        // ZOOM
        [values.zoomBoundary]: {
          'body': {
            zoom: values.zoomValue,
          },
          '.zoom': {
            zoom: values.zoomValue,
          },
          '.no-zoom': {
            zoom: 1 / values.zoomValue,
          },
          // AUTOCOMPLETE
          '.neutralize-zoom-autocomplete': {
            '& .MuiInputBase-input': {
              zoom: values.zoomValue,
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderWidth: 1.5,
            },
            '& .MuiFormLabel-root': {
              zoom: values.zoomValue,
            },
            '& legend': {
              zoom: values.zoomValue,
            },
            '& .MuiButtonBase-root': {
              zoom: values.zoomValue,
            },
          },
          '.MuiAutocomplete-popper': {
            zoom: 1 / values.zoomValue,
          },
          '.MuiAutocomplete-popper ul': {
            zoom: values.zoomValue,
          },
        },
      })}
    />
  )
}

export default GlobalStyles