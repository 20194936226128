import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

// COMPONENTS
import InputWithIcon from 'components/InputWithIcon/InputWithIcon'

// CONSTANTS
import { basePaths } from 'constants/paths'

// MUI
import Button from '@mui/material/Button'
import InputAdornment from '@mui/material/InputAdornment'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconPhone from '@mui/icons-material/Phone'

const SignIn = () => {
  const navigate = useNavigate()

  const [ phoneNumber, setPhoneNumber ] = useState('')

  const handleSubmitForm = (event) => {
    event.preventDefault()

    navigate(basePaths.requestVerification, { state: { phoneNumber }})
  }

  return (
    <Stack 
      spacing={24}
      alignItems='center'
      component='form'
      onSubmit={handleSubmitForm}
    >
      {/* PHONE NUMBER INPUT */}
      <InputWithIcon
        Icon={IconPhone}
        isRequired 
        label='Nomor Telpon'
        autoFocus
        type='tel'
        name='phoneNumber'
        value={phoneNumber}
        onChange={(event) => setPhoneNumber(event.target.value)}
        startAdornment={
          <InputAdornment position='start'>
            <Typography variant='body2'>
              +62
            </Typography>
          </InputAdornment>
        }
      />

      {/* SEND BUTTON */}
      <Button
        variant='contained'
        fullWidth
        type='submit'
        size='large'
        disabled={phoneNumber === ''}
      >
        Kirim Kode
      </Button>
    </Stack>
  )
}
  
export default SignIn