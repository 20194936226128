// ASSETS
import LogoProduct from 'assets/images/logos/product-logo.svg'

// CONSTANTS
import { values } from 'constants/values'

// MUI
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'

const ProductInfo = () => {
  return (
    <Link
      href='/'
      underline='none'
      display='flex'
      flexDirection='column'
      width='fit-content'
      alignSelf='center'
    >
      {/* PRODUCT LOGO */}
      <Box
        component='img'
        src={LogoProduct}
        alt=''
        height={40}
        marginBottom={12}
      />

      {/* PRODUCT TITLE */}
      <Typography
        variant='h3'
        color='text.primary'
        fontWeight={600}
        fontStyle='italic'
        textAlign='center'
        fontFamily={values.fontFamilyMontserrat}
      >
        {values.appName}
      </Typography>

      {/* PRODUCT CAPTION */}
      <Typography
        color='text.primary'
        textAlign='center'
        fontWeight={300}
        fontFamily={values.fontFamilyMontserrat}
      >
        LAPORAN KEJADIAN
      </Typography>
    </Link>
  )
}

export default ProductInfo