export const basePaths = {
  // AUTHENTICATION
  inputVerification: '/input-verification',
  requestVerification: '/request-verification',
  signIn: '/sign-in',
  signUp: '/sign-up',
  // PRIVATE
  createEvidence: '/create-evidence',
  evidenceDetail: '/evidence-detail',
  home: '/',
  profile: '/profile',
  thankYou: '/thank-you',
}