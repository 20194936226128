const keyAccessToken = 'vo-citizen-access-token'

// ACCESS TOKEN
export const setAccessTokenToLocalStorage = (accessToken) => {
  localStorage.setItem(keyAccessToken, accessToken)
}

export const readAccessTokenFromLocalStorage = () => {
  return localStorage.getItem(keyAccessToken)
}

export const removeAccessTokenFromLocalStorage = () => {
  return localStorage.removeItem(keyAccessToken)
}