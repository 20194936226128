// RAMDA
import { 
  equals, 
  isEmpty, 
  reject, 
} from 'ramda'
  
export const isObjectEmpty = (inputObject) => {
  return isEmpty(reject(equals(null), inputObject))
}

export const isRequestValid = (status) => {
  return status === 'Canceled' || status === 401 ? false : true
}