// MUIS
import { alpha } from '@mui/material/styles'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  backdrop: {
    backdropFilter: 'blur(2px)',
    zIndex: theme.zIndex.tooltip + 1,
  },
  socialShareIconButton: {
    width: 28,
    height: 28,
    backgroundColor: theme.palette.common.white,
    '& .MuiSvgIcon-root': {
      transform: 'rotate(-45deg)',
      color: theme.palette.grey[700],
    },
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.8),
    },
  },
}))

export default useStyles