// MUI STYLE
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  createEvidenceButton: {
    position: 'fixed',
    bottom: 24,
    right: 24,
  },
  divider: {
    borderColor: theme.palette.text.secondary,
    marginTop: 16,
    marginBottom: 24,
  },
  emptyEvidenceIcon: {
    width: 72,
    height: 72,
    color: theme.palette.action.active,
  },
}))

export default useStyles