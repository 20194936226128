import { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'

// CONSTANTS
import { optionList } from './dialogSocialShareConstants'
import { basePaths } from 'constants/paths'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUIS
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import OutlinedInput from '@mui/material/OutlinedInput'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { alpha, useTheme } from '@mui/material/styles'

// MUI ICONS
import IconClose from '@mui/icons-material/Close'

// STYLES
import useStyles from './dialogSocialShareUseStyles'

const DialogSocialShare = (props) => {
  const { dialogSocialShare, setDialogSocialShare } = props

  const classes = useStyles()
  
  const { 
    breakpointType,
    setSnackbarObject, 
  } = useContext(AllPagesContext)

  const theme = useTheme()

  const [ isUrlInputShown, setIsUrlInputShown ] = useState(false)

  const evidenceDetailUrl = dialogSocialShare 
    ? `${window.location.origin}${basePaths.evidenceDetail}/${dialogSocialShare.urlCode}` 
    : window.location.origin

  const handleCopyButtonClick = () => {
    navigator.clipboard.writeText(evidenceDetailUrl)
    setSnackbarObject({
      open: true,
      severity: 'info',
      title: '',
      message: 'Sukses menyalin tautan',
    })
  }

  const getShareIconButtonComponent = (item) => {
    return (
      <IconButton 
        className={classes.optionIconButton}
        sx={{
          backgroundColor: item.backgroundColor ?? theme.palette.common.white,
          color: item.color ?? theme.palette.action.active,
          '&:hover': {
            backgroundColor: item.backgroundColor 
              ? alpha(item.backgroundColor, 0.8) 
              : alpha(theme.palette.common.white, 0.8),
          },
        }}
        onClick={(event) => handleShareButtonClick(event, item.label)}
      >
        <item.icon/>
      </IconButton>
    )
  }

  const handleShareButtonClick = (event, label) => {
    event.preventDefault()

    if (label === optionList[0].label) setIsUrlInputShown(true)
  }

  useEffect(() => {
    if (breakpointType === 'xs') setDialogSocialShare(null)
  }, [breakpointType])

  return (
    <Dialog
      open={Boolean(dialogSocialShare)}
      onClose={() => setDialogSocialShare(null)}
      className={classes.dialogSocialShare}
    >
      {/* HEADER */}
      <DialogTitle 
        display='flex'
        flexDirection='row !important' 
        alignItems='center' 
        justifyContent='space-between'
        padding='0px !important'
        marginBottom={20}
        marginTop={-10}
      >
        {/* TITLE */}
        <Typography variant='h6'>
          Bagikan
        </Typography>

        {/* CLOSE ICON */}
        <IconButton onClick={() => setDialogSocialShare(null)}>
          <IconClose/>
        </IconButton>
      </DialogTitle>

      {/* CONTENT */}
      <DialogContent className='padding0'>
        {/* OPTION LIST */}
        <Stack
          direction='row'
          spacing={16} 
        >
          {optionList.map((item, index) => (
            <Stack 
              key={index}
              alignItems='center'
            >
              {/* ICON */}
              {item.container ? (
                <item.container url={window.location.href}>
                  {getShareIconButtonComponent(item)}
                </item.container>
              ) : getShareIconButtonComponent(item)}

              {/* LABEL */}
              <Typography variant='body2'>
                {item.label}
              </Typography>
            </Stack>
          ))}
        </Stack>

        {/* URL READ-ONLY INPUT */}
        {isUrlInputShown &&
        <FormControl 
          variant='outlined' 
          fullWidth
          className={classes.urlInput}
        >
          <OutlinedInput
            type='text'
            readOnly
            defaultValue={evidenceDetailUrl}
            label=''
            endAdornment={
              <InputAdornment position='end'>
                <Button 
                  variant='contained'
                  onClick={handleCopyButtonClick}
                  className={classes.urlInputCopyButton}
                  color='info'
                >
                  Copy
                </Button>
              </InputAdornment>
            }
          />
        </FormControl>}
      </DialogContent>
    </Dialog>
  )
}

DialogSocialShare.defaultProps = {}

DialogSocialShare.propTypes = {
  dialogSocialShare: PropTypes.object, 
  setDialogSocialShare: PropTypes.func.isRequired,
}

export default DialogSocialShare