import { createContext, useState } from 'react'

// CONSTANTS
import { values } from 'constants/values'

// MUIS
import useMediaQuery from '@mui/material/useMediaQuery'

// UTILITIES
import { readAccessTokenFromLocalStorage } from 'utilities/localStorage'

const AllPagesContext = createContext()

const AllPagesContextProvider = (props) => {
  // BREAKPOINT
  const isXsScreen = useMediaQuery((theme) => theme.breakpoints.only('xs'))
  const isSmScreen = useMediaQuery((theme) => theme.breakpoints.only('sm'))
  const isMdScreen = useMediaQuery((theme) => theme.breakpoints.only('md'))
  const isLgScreen = useMediaQuery((theme) => theme.breakpoints.only('lg'))
  const isXlScreen = useMediaQuery((theme) => theme.breakpoints.only('xl'))
  
  let breakpointType
  isXsScreen && (breakpointType = 'xs')
  isSmScreen && (breakpointType = 'sm')
  isMdScreen && (breakpointType = 'md')
  isLgScreen && (breakpointType = 'lg')
  isXlScreen && (breakpointType = 'xl')
  
  // LOADING
  const [ isLoading, setIsLoading ] = useState(false)
  
  // SNACKBAR
  const [ snackbarObject, setSnackbarObject ] = useState(values.initialSnackbarObject)

  // USER
  const [ token, setToken ] = useState(readAccessTokenFromLocalStorage())
  const [ userProfile, setUserProfile ] = useState('')

  return (
    <AllPagesContext.Provider
      value={{
        // BREAKPOINT
        breakpointType,
        // LOADING
        isLoading, setIsLoading,
        // SNACKBAR
        snackbarObject, setSnackbarObject,
        // USER
        token, setToken,
        userProfile, setUserProfile,
      }}
    >
      {props.children}
    </AllPagesContext.Provider>
  )
}

export { AllPagesContextProvider, AllPagesContext }