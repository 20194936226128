// MUI
import { grey } from '@mui/material/colors'
import { alpha } from '@mui/material/styles'

const white = '#FFFFFF'

export const palette = {
  // PRIMARY (YELLOW)
  primary: {
    main: '#FFB400',
    light: '#FFE4A1',
    dark: '#CC9000',
  },
  // TEXT (WHITE)
  text: {
    primary: white,
    secondary: grey[500],
    disabled: alpha(white, 0.38),
  },
  // DIVIDER
  divider: grey[500],
  // BACKGROUND
  background: {
    paper: '#1D1F2D',
    default: '#14141D',
  },
  // ACTION
  action: {
    active: grey[500],
  },
}

export const socialMedias = {
  whatsApp: '#65D072', //GREEN
  facebook: '#425893', // BLUE
  twitter: '#4D9FEB', // LIGHT BLUE
}