export const convertDate = (inputDate) => {
  if (!inputDate) return ''
  else {
    const date = new Date(inputDate)
    const newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    const options = { 
      weekday: 'long', 
      year: 'numeric', 
      month: 'long', 
      day: 'numeric',
    }
    const timezone = new Date().toTimeString().slice(9, 15)

    return date === 'Invalid Date' 
      ? inputDate 
      : `${newDate.toLocaleTimeString('id-ID', options).replace('pukul', '').replace('.', ':').slice(0, -3)} ${timezone}`
  }
}