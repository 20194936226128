import { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'

// CONSTANTS
import { basePaths } from 'constants/paths'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// LAYOUT
import Private from 'layouts/Private/Private'

function PrivateRoute(props) {
  const { children } = props

  const { token } = useContext(AllPagesContext)

  return (
    token ?
      <Private>   
        {children}
      </Private> : 
      <Navigate 
        replace 
        to={basePaths.signIn}
      />
  )
}

PrivateRoute.defaultProps = {}

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PrivateRoute