// MUIS
import { alpha } from '@mui/material/styles'

// MUI STYLES
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  stepper: {
    position: 'absolute',
    bottom: 12,
    justifyContent: 'center',
    '&.MuiPaper-root': {
      width: 'fit-content',
      left: '50%',
      transform: 'translate(-50%, 0)',
      backgroundColor: 'transparent',
    },
    '& .MuiMobileStepper-dot': {
      backgroundColor: 'white',
    },
    '& .MuiMobileStepper-dotActive': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  carousell: {
    borderRadius: 'inherit !important',
    width: '100%',
    '& .css-1abc02a, & .css-hn784z': {
      height: 'unset',
      top: '50%',
      transform: 'translate(0, -50%)',
    },
  },
  mediaBlurredBackground: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    filter: 'blur(8px)',
    WebkitFilter: 'blur(8px)',
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 2,
  },
  mediaItemVisual: {
    maxWidth: '100%',
    maxHeight: '100%',
    height: '100%',
    width: '100%',
    objectFit: 'contain',
    position: 'absolute',
    zIndex: 3,
  },
  mediaInvalidSource: {
    width: 40,
    height: 40,
  },
  mediaVideoMuteIconButton: {
    position: 'absolute',
    zIndex: 4,
    bottom: 12,
    right: 12,
    '& .MuiSvgIcon-root': {
      color: theme.palette.grey[700],
    },
  },
}))

export default useStyles