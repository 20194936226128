// CONSTANTS
import { basePaths } from 'constants/paths'

// PAGES
import CreateEvidence from 'pages/CreateEvidence/CreateEvidence'
import EvidenceDetail from 'pages/EvidenceDetail/EvidenceDetail'
import Home from 'pages/Home/Home'
import InputVerification from 'pages/InputVerification/InputVerification'
import Profile from 'pages/Profile/Profile'
import RequestVerification from 'pages/RequestVerification/RequestVerification'
import SignIn from 'pages/SignIn/SignIn'
import SignUp from 'pages/SignUp/SignUp'
import ThankYou from 'pages/ThankYou/ThankYou'

const routes = [
  // AUTHENTICATION
  {
    path: basePaths.signIn,
    element: <SignIn/>,
    routeType: 'authentication',
  },
  {
    path: basePaths.requestVerification,
    element: <RequestVerification/>,
    routeType: 'authentication',
  },
  {
    path: basePaths.inputVerification,
    element: <InputVerification/>,
    routeType: 'authentication',
  },
  {
    path: basePaths.signUp,
    element: <SignUp/>,
    routeType: 'authentication',
  },
  // PRIVATE
  {
    path: basePaths.createEvidence,
    element: <CreateEvidence/>,
    routeType: 'private',
  },
  {
    path: basePaths.home,
    element: <Home/>,
    routeType: 'private',
  },
  {
    path: basePaths.thankYou,
    element: <ThankYou/>,
    routeType: 'private',
  },
  {
    path: basePaths.profile,
    element: <Profile/>,
    routeType: 'private',
  },
  {
    path: `${basePaths.evidenceDetail}/:evidenceUrlCode`,
    element: <EvidenceDetail/>,
    routeType: 'private',
  },
]

export default routes