// PATH ICONS
import IconBodyBanishment from 'assets/images/pathIcons/IconBodyBanishment'
import IconCannabisAbuse from 'assets/images/pathIcons/IconCannabisAbuse'
import IconCorruption from 'assets/images/pathIcons/IconCorruption'
import IconDemonstration from 'assets/images/pathIcons/IconDemonstration'
import IconEarthquake from 'assets/images/pathIcons/IconEarthquake'
import IconEviction from 'assets/images/pathIcons/IconEviction'
import IconFighting from 'assets/images/pathIcons/IconFighting'
import IconGratification from 'assets/images/pathIcons/IconGratification'
import IconKidnapping from 'assets/images/pathIcons/IconKidnapping'
import IconPunch from 'assets/images/pathIcons/IconPunch'
import IconShield from 'assets/images/pathIcons/IconShield'
import IconTheft from 'assets/images/pathIcons/IconTheft'
import IconWildFire from 'assets/images/pathIcons/IconWildFire'

export const values = {
  // ALL PAGES
  appName: 'BUZZR',
  fontFamilyMontserrat: [ 'Montserrat', 'sans-serif' ].join(','),
  fontFamilyOpenSans: [ 'Open Sans', 'sans-serif' ].join(','),
  noInformationText: '[Tidak ada informasi]',

  // SNACKBAR
  initialSnackbarObject: {
    open: false,
    severity: 'success',
    title: '',
    message: '',
  },

  // TAGS
  tagList: [
    {
      id: 1,
      icon: IconCannabisAbuse,
      label: 'Kejahatan Transnational',
    },
    {
      id: 2,
      icon: IconEviction,
      label: 'Kejahatan Terhadap Kekayaan Negara',
    },
    {
      id: 3,
      icon: IconDemonstration,
      label: 'Pelanggaran HAM',
    },
    {
      id: 4,
      icon: IconFighting,
      label: 'Kejahatan Yang Berimplikasikan Kontinjensi',
    },
    {
      id: 5,
      icon: IconEarthquake,
      label: 'Bencana Alam',
    },
    {
      id: 6,
      icon: IconWildFire,
      label: 'Bencana non Alam',
    },
    {
      id: 7,
      icon: IconPunch,
      label: 'Bencana Sosial',
    },
    {
      id: 8,
      icon: IconBodyBanishment,
      label: 'Pelanggaran Dalam KUHP',
    },
    {
      id: 9,
      icon: IconCorruption,
      label: 'Pelanggaran di Luar KUHP',
    },
    {
      id: 10,
      icon: IconTheft,
      label: 'Kejahatan Konvensional/Nasional',
    },
    {
      id: 11,
      icon: IconGratification,
      label: 'Harta Benda',
    },
    {
      id: 12,
      icon: IconShield,
      label: 'Keamanan Negara',
    },
    {
      id: 13,
      icon: IconCorruption,
      label: 'Kejahatan/Pelanggaran Tertentu/Khusus',
    },
    {
      id: 14,
      icon: IconDemonstration,
      label: 'Pelanggaran/Gangguan terhadap Ketertiban Umum',
    },
    {
      id: 15,
      icon: IconKidnapping,
      label: 'Susila',
    },
  ],

  // ZOOM
  zoomBoundary: '@media only screen and (max-height: 800px)',
  noZoomBoundary: '@media only screen and (min-height: 801px)',
  zoomValue: 0.85,
}