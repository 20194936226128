import { useState, useEffect, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

// CONSTANTS
import { basePaths } from 'constants/paths'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// MUI
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconChat from '@mui/icons-material/Chat'

// SERVICES
import { postSignInUser } from 'services/auth'

// STYLES
import useStyles from './requestVerificationUseStyles'

// UTILITIES
import { censorPhoneNumber } from 'utilities/string'

const RequestVerification = () => {
  const classes = useStyles()

  const { 
    setIsLoading, 
    setSnackbarObject,
  } = useContext(AllPagesContext)

  const location = useLocation()
  const navigate = useNavigate()

  const initialVerificationOptionList = [
    {
      label: 'SMS ke',
      value: '****-****-****',
      command: 'SMS',
    },
    {
      label: 'WhatsApp ke',
      value: '****-****-****',
      command: 'WHATSAPP',
    },
  ]

  const [ verificationOptionList, setVerificationOptionList ] = useState(initialVerificationOptionList)

  // UPDATE THE VERIFICATION OPTIONS WITH THE REAL PHONE NUMBER
  useEffect(() => {
    if (location?.state?.phoneNumber) {
      setVerificationOptionList(current => {
        return current.map(item => {
          return { 
            ...item,
            value: censorPhoneNumber(`0${location?.state?.phoneNumber}`), 
          }
        })
      })
    }
  }, [location])

  const handleVerificationOptionClick = async (method, phoneNumber) => {
    setIsLoading(true)
    const abortController = new AbortController()
  
    const resultSignInUser = await postSignInUser(
      abortController.signal,
      { phoneNumber, method },
    )

    if (resultSignInUser.status === 200) navigate(
      basePaths.inputVerification, 
      { state: { phoneNumber, method }},
    )
    else setSnackbarObject({
      open: true,
      severity: 'error',
      title: '',
      message: `Gagal meminta verifikasi dengan error ${resultSignInUser.data.message}`,
    })

    abortController.abort()
    setIsLoading(false)
  }
  
  return (
    <Stack 
      width='100%'
      alignItems='center'
      alignSelf='center'
      maxWidth={480}
    >
      {/* INSTRUCTION TEXT */}
      <Typography
        variant='h6'
        color='text.primary'
        fontWeight={600}
        textAlign='center'
      >
        Verifikasi perangkat Anda terlebih dahulu
      </Typography>

      {/* DESCRIPTION TEXT */}
      <Typography
        variant='body2'
        color='text.primary'
        marginTop={24}
        marginBottom={40}
        lineHeight={1.625}
        textAlign='center'
      >
        Anda perlu memverifikasi agar akun Anda aman.
        <br/>
        Pilih salah satu metode verifikasi di bawah ini:
      </Typography>

      {/* VERIFICATION OPTION LIST */}
      <List 
        disablePadding
        className='width100'
      >
        {verificationOptionList.map((item, index) => (
          <ListItemButton 
            key={index}
            className={classes.verificationOptionItem}
            onClick={() => handleVerificationOptionClick(item.command, `0${location?.state?.phoneNumber}`)}
          >
            {/* ICON */}
            <ListItemIcon>
              <IconChat/>
            </ListItemIcon>

            {/* TEXT */}
            <ListItemText
              primary={item.label}
              secondary={item.value}
            />
          </ListItemButton>
        ))}
      </List>
    </Stack>
  )
}

export default RequestVerification