import { useState, useEffect, useContext, useRef } from 'react'

// COMPONENTS
import CardEvidence from './CardEvidence'
import DialogSocialShare from 'components/DialogSocialShare/DialogSocialShare'

// CONSTANTS
import { basePaths } from 'constants/paths'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// HOOKS
import useAxiosPrivate from 'hooks/useAxiosPrivate'

// MUIS
import Divider from '@mui/material/Divider'
import Fab from '@mui/material/Fab'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'

// MUI ICONS
import IconAdd from '@mui/icons-material/Add'
import IconDescription from '@mui/icons-material/Description'

// SERVICES
import { postSearchEvidenceList } from 'services/evidence'

// STYLES
import useStyles from './homeUseStyles'

// UTILITIES
import { isRequestValid } from 'utilities/validation'

const Home = () => {
  const classes = useStyles()

  const { 
    setIsLoading, 
    setSnackbarObject,
  } = useContext(AllPagesContext)

  const axiosPrivate = useAxiosPrivate()

  const infiniteScrollTigger = useRef(null)

  const theme = useTheme()

  const pageSize = 10

  const [ currentPage, setCurrentPage ] = useState(0)
  const [ evidenceList, setEvidenceList ] = useState([])
  const [ dialogSocialShare, setDialogSocialShare ] = useState(null)

  const getEvidenceListData = async () => {
    setIsLoading(true)
    const abortController = new AbortController()

    const resultEvidenceList = await postSearchEvidenceList(
      axiosPrivate,
      {
        page: currentPage,
        size: pageSize,
        sort: 'createdOn,desc',
      }, 
      abortController.signal,
    )

    if (resultEvidenceList?.status === 200) {
      if (resultEvidenceList?.data?.rows?.length > 0) {
        setCurrentPage(current => current + 1)
        setEvidenceList(current => [ ...current, ...resultEvidenceList?.data?.rows ])
      }
    }
    else if (isRequestValid(resultEvidenceList.status)) setSnackbarObject({
      open: true,
      severity: 'error',
      title: '',
      message: `Gagal mendapatkan daftar kejadian dengan error ${resultEvidenceList?.data?.message}`,
    })

    setIsLoading(false)
    abortController.abort()
  }

  // INFINITE SCROLL
  const observer = new IntersectionObserver(
    entries => {
      if (entries[0].isIntersecting) getEvidenceListData()
    },
    { threshold: 1 }
  )

  // INFINITE SCROLL
  useEffect(() => {  
    if (infiniteScrollTigger.current) observer.observe(infiniteScrollTigger.current)
  
    return () => {
      if (infiniteScrollTigger.current) observer.unobserve(infiniteScrollTigger.current)
    }
  }, [infiniteScrollTigger.current, currentPage])
  
  return (
    <>
      {evidenceList.length > 0 ? (
        // EVIDENCE LIST
        <Stack 
          backgroundColor={theme.palette.background.paper}
          padding={{ xs: 16, sm: 24, md: 40 }}
          divider={
            <Divider 
              orientation='horizontal' 
              flexItem 
              className={classes.divider}
            />
          }
        >
          {evidenceList.map((item, index) => (
            <CardEvidence
              key={index} 
              evidenceObject={item}
              onShareIconButtonClick={setDialogSocialShare}
            />
          ))}
        </Stack>
      ) : (
        // EMPTY EVIDENCE LIST INDICATOR
        <Stack
          alignItems='center'
          className='centerFixedPosition'
        >
          {/* ICON */}
          <IconDescription className={classes.emptyEvidenceIcon}/>

          {/* TEXT */}
          <Typography
            variant='body2'
            color='action.active'
          >
            Belum ada laporan
          </Typography>
        </Stack>
      )}

      {/* INFINITE SCROLL TIGGER */}
      <Stack ref={infiniteScrollTigger}/>

      {/* CREATE EVIDENCE */}
      <Fab 
        color='primary'
        className={classes.createEvidenceButton}
        href={basePaths.createEvidence}
      >
        <IconAdd/>
      </Fab>

      {/* DIALOG SOCIAL SHARE */}
      <DialogSocialShare
        dialogSocialShare={dialogSocialShare} 
        setDialogSocialShare={setDialogSocialShare}
      />
    </>
  )
}

export default Home