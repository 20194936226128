import { useState, useEffect, useContext, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

// COMPONENTS
import InputWithIcon from 'components/InputWithIcon/InputWithIcon'

// CONSTANTS
import { basePaths } from 'constants/paths'
import { values } from 'constants/values'

// CONTEXTS
import { AllPagesContext } from 'contexts/AllPagesContext'

// HOOKS
import useAxiosPrivate from 'hooks/useAxiosPrivate'

// MUI
import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Chip from '@mui/material/Chip'
import FormControl from '@mui/material/FormControl'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'

// MUI ICONS
import IconCheckBox from '@mui/icons-material/CheckBox'
import IconCheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank'
import IconDescription from '@mui/icons-material/Description'
import IconLocationOn from '@mui/icons-material/LocationOn'
import IconTag from '@mui/icons-material/Tag'

// SERVICES
import { postSubmitEvidence } from 'services/evidence'
import { getAddressFromLatitudeLongitude } from 'services/geocoder'

// STYLES
import useStyles from './createEvidenceUseStyles'
import '@uppy/core/dist/style.min.css'
import '@uppy/dashboard/dist/style.min.css'
import '@uppy/webcam/dist/style.min.css'

// UPPY
import { Dashboard } from '@uppy/react'
import Webcam from '@uppy/webcam'
import Tus from '@uppy/tus'
import Uppy from '@uppy/core'

// UTILITIES
import { isRequestValid } from 'utilities/validation'

const CreateEvidence = () => {
  const classes = useStyles()
  
  const navigate = useNavigate()
  
  const axiosPrivate = useAxiosPrivate()
  
  const { 
    setIsLoading, 
    setSnackbarObject,
  } = useContext(AllPagesContext)
  
  const uppyRef = useRef(new Uppy({ autoProceed: true, debug: true })
    .use(Webcam)
    .use(Tus, { endpoint: process.env.REACT_APP_TUS_ENDPOINT }))
  
  const initialFormObject = {
    description: '',
    tags: [],
    address: '',
    latitude: '',
    longitude: '',
    addressDetail: {},
  }

  const [ formObject, setFormObject ] = useState(initialFormObject)
  const [ mediaList, setMediaList ] = useState([])

  const handleFormObjectChange = (event) => {
    setFormObject(current => {
      return {
        ...current,
        [event.target.name]: event.target.value, 
      }
    })
  }

  const getCurrentLocation = () => {
    navigator.permissions.query({ name: 'geolocation' }).then(function(result) {
      if (result.state === 'granted') {
        navigator.geolocation.getCurrentPosition((position) => {
          setFormObject(current => {
            return {
              ...current,
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            }
          })
        })
      } 
      else if (result.state === 'prompt') getCurrentLocation()
      else if (result.state === 'denied') alert('Mohon izinkan akses lokasi')

      result.onchange = function() {
        getCurrentLocation()
      }
    })
  }

  const updateLocationAddress = async (abortController) => {
    setIsLoading(true)

    const resultLocationAddress = await getAddressFromLatitudeLongitude(
      axiosPrivate, 
      {
        lat: formObject.latitude, 
        lng: formObject.longitude,
        withDetails: true,
      }, 
      abortController.signal
    )
    
    if (resultLocationAddress.status === 200) {
      if (resultLocationAddress?.data?.addresses?.length > 0) {
        setFormObject(current => {
          return {
            ...current,
            address: resultLocationAddress?.data?.addresses[0]?.address ?? values.noInformationText,
            addressDetail: resultLocationAddress?.data?.addresses[0]?.details ?? {},
          }
        })
      }
    }
    else if (isRequestValid(resultLocationAddress.status)) {
      setSnackbarObject({
        open: true,
        severity: 'error',
        title: '',
        message: `Gagal mendapatkan alamat dengan error ${resultLocationAddress?.data?.message}`,
      })
    }
    
    setIsLoading(false)
  }
  
  const handleSubmitForm = async (event) => {
    event.preventDefault()
    setIsLoading(true)
    const abortController = new AbortController()
    
    const resultSubmitEvidence = await postSubmitEvidence(
      axiosPrivate,
      {
        address: formObject?.address ?? values.noInformationText,
        longitude: formObject?.longitude ?? 0,
        latitude: formObject?.latitude ?? 0,
        country: formObject?.addressDetail?.country ?? values.noInformationText,
        province: formObject?.addressDetail?.province ?? values.noInformationText,
        city: formObject?.addressDetail?.city ?? values.noInformationText,
        subDistrict: formObject?.addressDetail?.subDistrict ?? values.noInformationText,
        village: formObject?.addressDetail?.village ?? values.noInformationText,
        tagNos: formObject?.tags.map(item => item.id),
        description: formObject?.description ?? values.noInformationText,
        // REMOVE DUPLICATED ITEMS
        mediaRequests: [...new Map(mediaList.map(item => [item['name'], item])).values()].map(item => {
          return {
            contentType: item.meta.type,
            path: item.uploadURL.replace(`${process.env.REACT_APP_TUS_ENDPOINT}/`, ''),
            fileType: item.meta.type,
            fileName: item.name,
            fileSize: item.size,
          }
        }),
      }, 
      abortController.signal,
    )

    setIsLoading(false)
    abortController.abort()

    if (resultSubmitEvidence.status === 200) {
      setSnackbarObject({
        open: true,
        severity: 'success',
        title: '',
        message: 'Sukses mengirimkan kejadian',
      })

      navigate(basePaths.thankYou)
    }
    else if (isRequestValid(resultSubmitEvidence.status)) {
      setSnackbarObject({
        open: true,
        severity: 'error',
        title: '',
        message: `Gagal mengirimkan kejadian dengan error ${resultSubmitEvidence?.data?.message}`,
      })
    }
  }

  useEffect(() => {
    uppyRef.current.on('complete', (result) => {
      if (result?.successful?.length > 0) setMediaList(current => [ ...current, ...result?.successful ]) // CONCAT EXISTING MEDIAS
      else if (result?.failed?.length > 0) setSnackbarObject({
        open: true,
        severity: 'error',
        title: '',
        message: `Gagal menunggah media dengan error ${result?.failed[0]?.error}`,
      })
    })

    return () => uppyRef.current.close()
  }, [])

  useEffect(() => {
    getCurrentLocation()
  }, [navigator.geolocation, navigator.permissions])

  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()

    if (formObject.latitude && formObject.longitude) updateLocationAddress(abortController, isMounted)
    
    return () => {
      isMounted = false
      abortController.abort()
    }
  }, [formObject.latitude, formObject.longitude])

  return (
    <Stack
      component='form'
      onSubmit={handleSubmitForm}
      maxWidth='100%'
    >
      {/* MEDIA UPLOADER */}
      {uppyRef.current &&
      <Dashboard 
        uppy={uppyRef.current} 
        theme='dark'
        plugins={[ 'Webcam' ]} 
        className={classes.mediaUploader}
      />}

      {/* FORM */}
      <Stack
        spacing={24}
        marginTop={32}
        width='100%'
        maxWidth='100%'
      >
        {/* DESCRIPTION INPUT */}
        <InputWithIcon
          Icon={IconDescription}
          isRequired
          label='Uraian Singkat'
          autoFocus
          type='text'
          name='description'
          value={formObject.description}
          onChange={handleFormObjectChange}
        />

        {/* TAGS AUTOCOMPLETE */}
        <Stack
          direction='row'
          alignItems='center'
          spacing={12}
          width='100%'
          maxWidth='100%'
        >
          {/* ICON */}
          <IconTag 
            color='action' 
            className='inputIcon'
          />

          {/* AUTOCOMPLETE */}
          <FormControl 
            className='no-zoom' 
            fullWidth
          >
            <Autocomplete
              multiple
              fullWidth
              className='neutralize-zoom-autocomplete'
              limitTags={2}
              options={values.tagList}
              getOptionLabel={(option) => option.label}
              renderOption={(props, option, { selected }) => (
                <ListItem 
                  {...props}
                  className={`${props.className} ${classes.autocompleteListItem}`}
                >
                  {/* CHECKBOX */}
                  <ListItemIcon className={classes.autocompleteListItemIcon}>
                    <Checkbox
                      checked={selected}
                      icon={<IconCheckBoxOutlineBlank fontSize='small'/>}
                      checkedIcon={<IconCheckBox fontSize='small'/>}
                    />
                  </ListItemIcon>
                    
                  {/* LABEL */}
                  <ListItemText primary={option.label}/>
                </ListItem>
              )}
              renderInput={(params) => (
                <TextField 
                  {...params} 
                  label='Tag'
                  name='tag'
                  variant='standard'
                  className={classes.autocompleteTextField}
                  // required
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip 
                    key={index}
                    variant='filled'
                    icon={<option.icon fontSize='small'/>} 
                    label={option.label} 
                    color='primary'
                    className={classes.autocompleteChip}
                    {...getTagProps({ index })} 
                  />
                ))
              }
              value={formObject.tags}
              onChange={(event, newValue) => {
                setFormObject(current => {
                  return {
                    ...current,
                    tags: newValue,
                  }
                })
              }}
            />
          </FormControl>
        </Stack>

        {/* LOCATION INPUT */}
        <InputWithIcon
          Icon={IconLocationOn}
          isRequired
          label='Lokasi'
          type='text'
          name='address'
          value={formObject.address}
          onChange={handleFormObjectChange}
        />

        {/* ACTION BUTTON */}
        <Button
          variant='contained'
          fullWidth
          size='large'
          type='submit'
        >
          Kirim
        </Button>
      </Stack>
    </Stack>
  )
}
  
export default CreateEvidence